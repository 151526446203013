<template>
  <div
    @click="scrollTop"
    v-show="visible"
    class="bottom-right d-md-none d-flex"
    :style="{ zIndex: 5 }"
  >
    <v-icon size="lg" color="#000000">mdi-arrow-up-thin</v-icon>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    scrollTop: function() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 20);
    },
    scrollListener: function() {
      this.visible = window.scrollY > 150;
    },
  },
  mounted: function() {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function() {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style scoped>
.bottom-right {
  position: fixed;
  bottom: 2em;
  right: 1.5em;
  cursor: pointer;
  background-color: cyan;
  border-radius: 50%;

  width: 35px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
