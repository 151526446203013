export const LAYERS_DEFAULT = [
  {
    name: "Ortofoto-base",
    categoria: "",
    visible: true,
    visibleUI: false,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "",
    componentName: "OrtofotoLayer",
    description: "",
    downloadLink: "",
    zIndex: 100,
    props: {
      source: {
        type: "raster",
        tiles: [
          "http://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
          //"http://territorioatingido.com.br/geoserver/territorio-atingido/wms?service=WMS&version=1.1.0&request=GetMap&layers=territorio-atingido%3AZR1f&bbox={bbox-epsg-3857}&transparent=true&width=768&height=404&srs=EPSG%3A3857&styles=&format=image%2Fpng"
        ],
        transparent: true,
      },
    },
  },
  {
    name: "Ortofoto-antes-bento",
    categoria: "ortofoto-antes",
    visible: true,
    visibleUI: false,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "",
    componentName: "OrtofotoLayer",
    description: "",
    downloadLink: "",
    zIndex: 102,
    props: {
      source: {
        type: "raster",
        tiles: [
          "http://207.244.228.113/geoserver/territorio-atingido/wms?service=WMS&version=1.1.0&request=GetMap&layers=territorio-atingido%3At0_c&bbox={bbox-epsg-3857}&transparent=true&width=768&height=768&srs=EPSG%3A3857&styles=&format=image%2Fpng",
          //"http://territorioatingido.com.br/geoserver/territorio-atingido/wms?service=WMS&version=1.1.0&request=GetMap&layers=territorio-atingido%3At0&bbox={bbox-epsg-3857}&transparent=true&width=768&height=415&srs=EPSG%3A3857&styles=&format=image%2Fpng"
          //"http://territorioatingido.com.br/geoserver/territorio-atingido/wms?service=WMS&version=1.1.0&request=GetMap&layers=territorio-atingido%3AT0_ANTES_wgs&bbox={bbox-epsg-3857}&transparent=true&width=768&height=386&srs=EPSG%3A3857&styles=&format=image%2Fpng",
          // 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
        ],

        transparent: true,
      },
    },
  },
  {
    name: "Ortofoto-antes-d",
    categoria: "ortofoto-antes",
    visible: true,
    visibleUI: false,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "",
    componentName: "OrtofotoLayer",
    description: "",
    downloadLink: "",
    zIndex: 102,
    props: {
      source: {
        type: "raster",
        tiles: [
          "http://207.244.228.113/geoserver/territorio-atingido/wms?service=WMS&version=1.1.0&request=GetMap&layers=territorio-atingido%3At0_d&bbox={bbox-epsg-3857}&transparent=true&width=768&height=768&srs=EPSG%3A3857&styles=&format=image%2Fpng",
          //"http://territorioatingido.com.br/geoserver/territorio-atingido/wms?service=WMS&version=1.1.0&request=GetMap&layers=territorio-atingido%3At0&bbox={bbox-epsg-3857}&transparent=true&width=768&height=415&srs=EPSG%3A3857&styles=&format=image%2Fpng"
          //"http://territorioatingido.com.br/geoserver/territorio-atingido/wms?service=WMS&version=1.1.0&request=GetMap&layers=territorio-atingido%3AT0_ANTES_wgs&bbox={bbox-epsg-3857}&transparent=true&width=768&height=386&srs=EPSG%3A3857&styles=&format=image%2Fpng",
          // 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
        ],

        transparent: true,
      },
    },
  },
  {
    name: "Ortofoto-antes-e",
    categoria: "ortofoto-antes",
    visible: true,
    visibleUI: false,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "",
    componentName: "OrtofotoLayer",
    description: "",
    downloadLink: "",
    zIndex: 102,
    props: {
      source: {
        type: "raster",
        tiles: [
          "http://207.244.228.113/geoserver/territorio-atingido/wms?service=WMS&version=1.1.0&request=GetMap&layers=territorio-atingido%3At0_e&bbox={bbox-epsg-3857}&transparent=true&width=768&height=768&srs=EPSG%3A3857&styles=&format=image%2Fpng",
          //"http://territorioatingido.com.br/geoserver/territorio-atingido/wms?service=WMS&version=1.1.0&request=GetMap&layers=territorio-atingido%3At0&bbox={bbox-epsg-3857}&transparent=true&width=768&height=415&srs=EPSG%3A3857&styles=&format=image%2Fpng"
          //"http://territorioatingido.com.br/geoserver/territorio-atingido/wms?service=WMS&version=1.1.0&request=GetMap&layers=territorio-atingido%3AT0_ANTES_wgs&bbox={bbox-epsg-3857}&transparent=true&width=768&height=386&srs=EPSG%3A3857&styles=&format=image%2Fpng",
          // 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
        ],

        transparent: true,
      },
    },
  },

  {
    name: "Ortofoto-antes-g",
    categoria: "ortofoto-antes",
    visible: true,
    visibleUI: false,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "",
    componentName: "OrtofotoLayer",
    description: "",
    downloadLink: "",
    zIndex: 102,
    props: {
      source: {
        type: "raster",
        tiles: [
          "http://207.244.228.113/geoserver/territorio-atingido/wms?service=WMS&version=1.1.0&request=GetMap&layers=territorio-atingido%3At0_g&bbox={bbox-epsg-3857}&transparent=true&width=768&height=768&srs=EPSG%3A3857&styles=&format=image%2Fpng",
          //"http://territorioatingido.com.br/geoserver/territorio-atingido/wms?service=WMS&version=1.1.0&request=GetMap&layers=territorio-atingido%3At0&bbox={bbox-epsg-3857}&transparent=true&width=768&height=415&srs=EPSG%3A3857&styles=&format=image%2Fpng"
          //"http://territorioatingido.com.br/geoserver/territorio-atingido/wms?service=WMS&version=1.1.0&request=GetMap&layers=territorio-atingido%3AT0_ANTES_wgs&bbox={bbox-epsg-3857}&transparent=true&width=768&height=386&srs=EPSG%3A3857&styles=&format=image%2Fpng",
          // 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
        ],

        transparent: true,
      },
    },
  },
  {
    name: "Apicultura",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "apicultura",
    zIndex: 158,
    componentName: "PointIconDefault",
    description:
      "Criação de abelhas da espécie Apis mellífera para a extração de mel, cera, pólen e própolis. Localmente é valorizado o mel produzido no entorno das plantas nativas como assa-peixe.",
    downloadLink: "",
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome='apicultura'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "apicultura",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.08,
        16,
        0.11,
        18,
        0.16,
        20,
        0.2,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Bambuzal",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "bambuzal",
    iconName: "",
    componentName: "FillPatternDefault",
    zIndex: 100,
    description:
      "Touceiras de bambu são utilizadas nas comunidades para a confecção de varas de pesca, e como matéria prima para construções de uso rural, como galinheiro, chiqueiro, cercas, entre outros.",
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao_pols_ctb&PROPERTYNAME=uso_ocupa,geom&CQL_FILTER=uso_ocupa='bambuzal'&maxFeatures=500&outputFormat=application%2Fjson",
        //"http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao_pol&PROPERTYNAME=uso_ocupa,geom&CQL_FILTER=uso_ocupa='bambuzal'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
  },
  {
    name: "Brejo",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "brejo",
    componentName: "PointIconDefault",
    description:
      "Área de solo encharcado, especialmente em baixadas, podendo surgir a partir de nascentes. Importante para a manutenção do microclima e para saciar a sede dos animais. Algumas espécies de plantas típicas são gramíneas e taboa.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome='brejo'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "brejo",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.12,
        18,
        0.25,
        20,
        0.6,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Chiqueiros",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "chiqueiro",
    componentName: "PointIconDefault",
    description:
      "Edificação para abrigar suínos, construída com alvenaria reforçada e piso cimentado, facilitando a limpeza e higienização. Geralmente parte é descoberta e parte coberta, onde existem bebedouros com água sempre disponível.",
    downloadLink: "",
    zIndex: 157,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome='chiqueiro'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "chiqueiro",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.15,
        16,
        0.2,
        18,
        0.3,
        20,
        0.6,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Currais",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "curral",
    componentName: "PointIconDefault",
    description:
      "Os currais destinam-se à criação de bovinos, sendo por vezes, de caprinos. São fechados por tábuas espaçadas permitindo a ventilação, com área coberta geralmente utilizada para a ordenha e o espaço descoberto para a apartação.",
    downloadLink: "",
    zIndex: 156,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_unidades&PROPERTYNAME=nome,geom&CQL_FILTER=nome=%27curral%27&&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "curral",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.15,
        16,
        0.2,
        18,
        0.3,
        20,
        0.6,
      ],
      "icon-allow-overlap": true,
    },
  },
  // {
  //   name: "Edificações",
  //   categoria: "uso_ocupacao",
  //   visible: false,
  //   visibleUI: true,
  //   colorUI: "#d4ecd5",
  //   pattern: "edificacoes",
  //   iconName: "",
  //   componentName: "FillPatternDefault",
  //   zIndex: 100,
  //   description:
  //     "Edificações acessórias em parte de uso rural relacionadas às atividades produtivas, e em parte ligadas ao cotidiano da moradia. Nesta categoria estão agrupadas edificações como: Área de serviço, garagem, piscina, depósito, paiol, área de churrasco, depósito, pergolado, coberta de lenha, entre outras. O paiol é uma edificação destinada ao armazenamento de grãos, farelo, ração, milho e entre outros. Geralmente é elevada do solo e possui o telhado em duas águas. Já o depósito ou galpão é destinado ao armazenamento de ferramentas, maquinário e materiais. A coberta de lenha é uma edificação muito difundida no território, anexa às casas, mantém as lenhas secas para seu uso nos fornos e fogões.",
  //   props: {
  //     source: {
  //       type: "geojson",
  //       generateId: true,
  //       data:
  //         "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao_pol&PROPERTYNAME=uso_ocupa,geom&CQL_FILTER=uso_ocupa='outras edificacoes'&maxFeatures=500&outputFormat=application%2Fjson",
  //     },
  //   },
  // },
  {
    name: "Estábulos",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "estabulo",
    componentName: "PointIconDefault",
    description:
      "É comum os cavalos serem criados soltos, pastando por toda a comunidade, havendo um cocho coberto para a complementação da alimentação. Em outros casos, são abrigados em baias cobertas com fechamento de tábuas.",
    downloadLink: "",
    zIndex: 155,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome='estabulo'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "estabulo",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.15,
        16,
        0.2,
        18,
        0.3,
        20,
        0.6,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Fornos de Carvão",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "forno-de-carvao",
    componentName: "PointIconDefault",
    description:
      "Utilizado para a produção de carvão vegetal por meio da queima de madeira. Geralmente é feito com tijolinhos maciços, tendo a base cilíndrica escavada na encosta e a parte superior construída em formato semi esférico.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome='fornos de carvao'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "forno-de-carvao",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.15,
        16,
        0.2,
        18,
        0.4,
        20,
        0.6,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Galinheiros",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "galinheiro",
    componentName: "PointIconDefault",
    description:
      "Localizado geralmente próximo da casa, com fechamento de bambu ou tela de alambrado, e piso de terra que permite às aves ciscarem pela área. As galinhas costumam ser alimentadas com restos da horta, de comida e milho.",
    downloadLink: "",
    zIndex: 159,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome=%27galinheiro%27&maxFeatures=120&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "galinheiro",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.05,
        16,
        0.1,
        18,
        0.15,
        20,
        0.4,
        22,
        0.6,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Habitações",
    categoria: "uso_ocupacao",
    visible: true,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "casa3",
    componentName: "PointIconDefault",
    description:
      "Destina-se à moradia e ao convívio familiar, por vezes abrigando uma família extensa, coabitando avós, tios e primos. É comum as casas serem construídas pelos próprios moradores, e em sistema de mutirões.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_habitacao&maxFeatures=3000&outputFormat=application%2Fjson",
        // "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome=%27habitacao%27&maxFeatures=10&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "casa3",
      "icon-size": [
        "interpolate",
        ["exponential", 1],
        ["zoom"],
        14,
        0.04,
        17,
        0.03,
        18,
        0.04,
        20,
        0.08,
      ],
      "icon-allow-overlap": false,
    },
  },
  {
    name: "Hortas",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "horta",
    componentName: "PointIconDefault",
    description:
      "Geralmente próxima das casas, a horta é onde se cultiva saúde, sabores e saberes. Conduzida principalmente pelas mulheres, encontramos lá as plantas medicinais e os alimentos sem uso de agrotóxicos.",
    downloadLink: "",
    zIndex: 160,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_unidades&PROPERTYNAME=nome,geom&CQL_FILTER=nome=%27horta%27&maxFeatures=90&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "horta",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.04,
        16,
        0.05,
        18,
        0.09,
        20,
        0.15,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Jardins",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "jardim2",
    componentName: "PointIconDefault",
    description:
      "O jardim embeleza e deixa o ambiente mais agradável, trazendo bem-estar à família e aos visitantes. Geralmente é plantado em diferentes áreas do terreno, em frente às casas, nas laterais, em canteiros que acompanham a construção.",
    downloadLink: "",
    zIndex: 154,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_unidades&PROPERTYNAME=nome,geom&CQL_FILTER=nome=%27jardim%27&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "jardim2",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.01,
        16,
        0.05,
        18,
        0.1,
        20,
        0.15,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Mata Nativa",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "mata-nativa10", // tava mata-nativa2
    iconName: "",
    componentName: "FillPatternDefault",
    zIndex: 100,
    description:
      "Utilizada pelas comunidades para coleta de lenha seca, casca de árvores, frutos e folhas medicinais. Na região, predomina o bioma da Mata Atlântica, sendo as espécies mais comuns: pindaíba, vinhático, candeia, pau-jacaré e jacarandá.",
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao_pol&PROPERTYNAME=uso_ocupa,geom&CQL_FILTER=uso_ocupa='mata nativa'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
  },
  {
    name: "Moinhos d'água",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "moinho",
    componentName: "PointIconDefault",
    description:
      "Edificação acessória que possui um mecanismo capaz de aproveitar a força da movimentação de águas, que permite a moagem de grãos ou funcionamento de uma bomba de água. Amplamente utilizado para a fabricação de fubá de milho.",
    downloadLink: "",
    zIndex: 151,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome='moinho'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "moinho",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.2,
        18,
        0.5,
        20,
        0.8,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Outras Edificações",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "outras_edificacoes",
    componentName: "PointIconDefault",
    description:
      "Nesta categoria estão agrupadas edificações acessórias de uso rural, assim como as ligadas ao cotidiano da casa, a exemplo da área de serviço, garagem, piscina, paiol, área de churrasco, depósito, pergolado, coberta de lenha, etc.",
    downloadLink: "",
    zIndex: 152,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome='outras edificacoes'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "outras_edificacoes",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.14,
        16,
        0.1,
        18,
        0.1,
        20,
        0.3,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Pastagem",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "pastagem",
    iconName: "",
    componentName: "PastagemLayer",
    zIndex: 100,
    description:
      "Área de manejo e pastejo de animais, sobretudo bovinos, que aceita topografia mais acidentada, com vegetação própria para a alimentação do gado, que fica solto. São mais comuns na comunidade: braquiária, braquiarão e capim-gordura.",
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao_pol&PROPERTYNAME=uso_ocupa,geom&CQL_FILTER=uso_ocupa='pastagem'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
  },
  {
    name: "Cachoeiras",
    categoria: "recurso_hidrico",
    visible: true,
    visibleUI: true,
    colorUI: "#82deea",
    pattern: "",
    iconName: "cachoeira",
    componentName: "PointIconDefault",
    description:
      "Local de recreação e sociabilidade das comunidades, onde se encontram os amigos e parentes para banhos. Nas praias existentes nas margens, as pessoas fazem piquenique, churrascos ou acampam.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_hidro_recursos_hidricos&PROPERTYNAME=classific,geom&CQL_FILTER=classific='cachoeira'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "cachoeira",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.2,
        16,
        0.4,
        18,
        0.4,
        20,
        0.6,
      ],
      "icon-allow-overlap": true,
    },
  },

  {
    name: "Córregos",
    categoria: "recurso_hidrico",
    visible: false,
    visibleUI: true,
    colorUI: "#8BE7FC",
    pattern: "",
    iconName: "",
    componentName: "Corrego",
    description: "",
    downloadLink: "",
    zIndex: 100,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_recursos_hidricos_corregos_ctb&maxFeatures=3000&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
    },
  },
  {
    name: "Plantações Perenes",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "plantacao-perene4",
    iconName: "",
    componentName: "FillPatternDefault",
    zIndex: 100,
    description:
      "Plantio de espécies de ciclo longo que podem durar vários anos, não precisando ser replantadas após a colheita. Também estão incluídas nessa categoria as plantações semi-perenes; a espécie mais cultivada é a cana-de-açúcar.",
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao_pol&PROPERTYNAME=uso_ocupa,geom&CQL_FILTER=uso_ocupa='plantacao perene'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
  },
  {
    name: "Pomares",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "pomar10", //tava pomar4
    iconName: "",
    componentName: "FillPatternDefault",
    zIndex: 100,
    description:
      "Local onde as famílias cultivam a terra com diferentes espécies frutíferas (nativas e/ou exóticas) e obtêm colheitas abundantes que contribuem na alimentação e nutrição, assim como para a biodiversidade do agroecossistema.",
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao_pol&PROPERTYNAME=uso_ocupa,geom&CQL_FILTER=uso_ocupa='pomar'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
  },

  {
    name: "Quintais",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "quintal23",
    iconName: "",
    componentName: "FillPatternDefault",
    zIndex: 100,
    description:
      "Estão ao redor da casa contendo terreiro, jardins, roça, hortas, pomares, galinheiros, etc. As espécies são cultivadas de forma integrada e consorciada. As famílias utilizam para secar roupa, fazer festas e onde as crianças brincam.",
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Apoligonos_corrigidos&PROPERTYNAME=uso_ocupa,geom&CQL_FILTER=uso_ocupa='quintal'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
  },

  {
    name: "Roças",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "roca3",
    iconName: "",
    componentName: "FillPatternDefault",
    zIndex: 100,
    description:
      "As roças diversificadas geralmente são cultivadas em grotas, preferencialmente nos períodos de chuva. As culturas temporárias são plantadas de forma consorciada entre si. As principais espécies são: milho, feijão e mandioca.",
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao_pols_ctb&PROPERTYNAME=uso_ocupa,geom&CQL_FILTER=uso_ocupa='roca'&maxFeatures=500&outputFormat=application%2Fjson",
        // "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao_pol&PROPERTYNAME=uso_ocupa,geom&CQL_FILTER=uso_ocupa='roca'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
  },
  {
    name: "Silvicultura",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "silvicultura2",
    iconName: "",
    componentName: "FillPatternDefault",
    zIndex: 100,
    description:
      "Cultivo de espécies florestais com manejo e ciclos de produção diferentes dos agrícolas. Nas comunidades, a espécie mais utilizada é o eucalipto, com diferentes variedades, cultivado, sobretudo, para a produção de carvão vegetal.",
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao_pol&PROPERTYNAME=uso_ocupa,geom&CQL_FILTER=uso_ocupa='sivilcultura'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
  },
  {
    name: "Viveiros",
    categoria: "uso_ocupacao",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "viveiro",
    componentName: "PointIconDefault",
    description:
      "Estrutura geralmente feita com peças de madeira ou metálicas, suspensa do solo, com fechamento em tela, destinada à criação de aves como de codornas, faisões, pássaros.",
    downloadLink: "",
    zIndex: 153,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Aviveiro_pri&maxFeatures=50&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "viveiro",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.3,
        16,
        0.3,
        18,
        0.3,
        20,
        0.7,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Bares",
    categoria: "referencia_comunitaria",
    visible: false,
    visibleUI: true,
    colorUI: "#82deea",
    pattern: "",
    iconName: "bar",
    componentName: "PointIconDefault",
    description:
      "Ponto de encontro nos finais de semana de moradores, parentes e amigos. Tradicionalmente frequentado por visitantes e viajantes, bem como outros que realizavam atividades de turismo e lazer local.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_bar&maxFeatures=3000&outputFormat=application%2Fjson",
        //"http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao_pontos&PROPERTYNAME=nome,geom&CQL_FILTER=nome=%27bar%27&maxFeatures=500&outputFormat=application%2Fjson"
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "bar",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.05,
        16,
        0.08,
        18,
        0.1,
        20,
        0.18,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Campos de Futebol",
    categoria: "referencia_comunitaria",
    visible: true,
    visibleUI: true,
    colorUI: "#ffba55",
    pattern: "",
    iconName: "campo-de-futebol",
    componentName: "PointIconDefault",
    description:
      "Local de encontro e socialização onde tanto os adultos quanto as crianças jogavam futebol, recebiam times visitantes e em torno do qual se reuniam amigos, familiares para torcer e acompanhar os torneios.",
    downloadLink: "",
    zIndex: 152,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_campo_futebol&maxFeatures=3000&outputFormat=application%2Fjson",
        //"http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome='campo de futebol'&maxFeatures=50&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "campo-de-futebol",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.1,
        16,
        0.1,
        18,
        0.1,
        20,
        0.3,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Cemitérios",
    categoria: "referencia_comunitaria",
    visible: true,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "cemiterio",
    componentName: "PointIconDefault",
    description:
      "Local da comunidade que conserva a memória do ente querido que faleceu. Desde o rompimento, os atingidos lutam pelo direito de enterrar seus mortos nas comunidades que foram destruídas pela lama, e pelo acesso às sepulturas.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_cemiterio&maxFeatures=3000&outputFormat=application%2Fjson",
        //"http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome='cemiterio'&maxFeatures=50&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "cemiterio",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.2,
        18,
        0.3,
        20,
        0.8,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Capelas",
    categoria: "referencia_comunitaria",
    visible: true,
    visibleUI: true,
    colorUI: "#ffba55",
    pattern: "",
    iconName: "capela",
    componentName: "PointIconDefault",
    description:
      "Templo onde são realizadas as celebrações católicas da comunidade, incluindo as festividades. Os moradores mantêm diariamente a limpeza e organização, e muitos fiéis vão todos os dias às capelas para fazerem suas orações.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_capela&maxFeatures=3000&outputFormat=application%2Fjson",
        //"http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome='capela'&maxFeatures=50&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "capela",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.15,
        18,
        0.3,
        20,
        0.7,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Cruzeiros",
    categoria: "referencia_comunitaria",
    visible: true,
    visibleUI: true,
    colorUI: "#ffba55",
    pattern: "",
    iconName: "cruzeiro",
    componentName: "PointIconDefault",
    description:
      "Cruz geralmente de madeira ou pedra, localizada próxima das igrejas, capelas e em espaços de destaque. Nas comunidades atingidas estão principalmente relacionadas à Festa de Santa Cruz, fazendo parte do trajeto das procissões.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_cruzeiro&maxFeatures=3000&outputFormat=application%2Fjson",
        //"http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao_pontos&PROPERTYNAME=nome,geom&CQL_FILTER=nome='cruzeiro'&maxFeatures=50&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "cruzeiro",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.2,
        18,
        0.5,
        20,
        0.8,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Escolas",
    categoria: "referencia_comunitaria",
    visible: true,
    visibleUI: true,
    colorUI: "#ffba55",
    pattern: "",
    iconName: "escola",
    componentName: "PointIconDefault",
    description:
      "Nas escolas da zona rural, o ensino considera o modo de vida das comunidades, sendo apropriado aos aspectos culturais e sociais das crianças e adolescentes. São um importante local de encontro entre os pais, alunos e professores. ",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_escola&maxFeatures=3000&outputFormat=application%2Fjson",
        //"http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_referencias_comunitarias_ctb&PROPERTYNAME=uso_ocupac,geom&CQL_FILTER=uso_ocupac=%27escola%27&maxFeatures=3000&outputFormat=application%2Fjson"
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "escola",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.2,
        18,
        0.5,
        20,
        0.8,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Ginásio Poliesportivo",
    categoria: "referencia_comunitaria",
    visible: true,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "ginasio",
    componentName: "PointIconDefault",
    description:
      "Ponto de encontro para prática de esportes em local coberto, frequentado tanto por adultos quanto por crianças, pelos próprios moradores e por  visitantes das comunidades vizinhas.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_quadra&maxFeatures=3000&outputFormat=application%2Fjson",
        //"http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao_pontos&PROPERTYNAME=nome,geom&CQL_FILTER=nome=%27ginasio%20poliesportivo%27&maxFeatures=50&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "ginasio",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.3,
        18,
        0.4,
        20,
        0.8,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Igrejas",
    categoria: "referencia_comunitaria",
    visible: true,
    visibleUI: true,
    colorUI: "#ffba55",
    pattern: "",
    iconName: "igreja",
    componentName: "PointIconDefault",
    description:
      "Nesta categoria estão incluídas as igrejas católicas e as evangélicas, sendo o local onde os moradores e visitantes, principalmente das comunidades vizinhas, se reúnem para encontros e celebrações religiosas.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_igreja&maxFeatures=3000&outputFormat=application%2Fjson",
        //"http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome='igreja'&maxFeatures=50&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "igreja",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.2,
        18,
        0.3,
        20,
        0.8,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Postos de Saúde",
    categoria: "referencia_comunitaria",
    visible: true,
    visibleUI: true,
    colorUI: "#ffba55",
    pattern: "",
    iconName: "posto_saude",
    componentName: "PointIconDefault",
    description:
      "Equipamento comunitário público destinado ao atendimento básico e serviços de saúde às pessoas das comunidades.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_posto_saude&maxFeatures=3000&outputFormat=application%2Fjson",
        //"http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_uso_ocupacao&PROPERTYNAME=nome,geom&CQL_FILTER=nome='posto de saude'&maxFeatures=50&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "posto_saude",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.12,
        16,
        0.15,
        18,
        0.2,
        20,
        0.52,
      ],
      "icon-allow-overlap": true,
    },
  },

  {
    name: "Muros Arqueológicos",
    categoria: "referencia_comunitaria",
    visible: false,
    visibleUI: true,
    colorUI: "#8BE7FC",
    pattern: "",
    iconName: "",
    componentName: "MuroDePedras",
    description:
      "A técnica construtiva faz parte dos modos de fazer das comunidades desde a sua formação. A maioria dos muros de pedra levantados no cadastro foram qualificados como benfeitorias, e assim não constam nos mapas comunitários. ",
    downloadLink: "",
    zIndex: 100,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_historico_arqueologico_ctb&maxFeatures=3000&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
    },
  },

  {
    name: "Nascentes",
    categoria: "recurso_hidrico",
    visible: false,
    visibleUI: true,
    colorUI: "#82deea",
    pattern: "",
    iconName: "nascente",
    componentName: "PointIconDefault",
    description:
      "A nascente é um local onde a água do lençol freático aflora começando assim um curso d’água: cabeceira, fonte, mina. As nascentes são encontradas nas comunidades em terrenos em meio às matas e/ou nas grotas entre morros.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_hidro_recursos_hidricos&PROPERTYNAME=classific,geom&CQL_FILTER=classific='nascente'&maxFeatures=50&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "nascente",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.03,
        18,
        0.05,
        20,
        0.1,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Piscicultura",
    categoria: "recurso_hidrico",
    visible: false,
    visibleUI: true,
    colorUI: "#82deea",
    pattern: "",
    iconName: "piscicultura",
    componentName: "PointIconDefault",
    description:
      "Criação de peixes em açudes e lagoas, amplamente utilizada pelas comunidades para a prática de pesca artesanal, lazer, alimentação e complementação de renda. As principais espécies criadas são: tilápia, lambari, bagre e traíra.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_piscicultura&maxFeatures=3000&outputFormat=application%2Fjson",
        //"http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_hidro_recursos_hidricos&PROPERTYNAME=classific,geom&CQL_FILTER=classific='piscicultura'&maxFeatures=500&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "piscicultura",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.09,
        16,
        0.12,
        18,
        0.18,
        20,
        0.2,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Reservatórios de Água",
    categoria: "recurso_hidrico",
    visible: true,
    visibleUI: true,
    colorUI: "#82deea",
    pattern: "",
    iconName: "reservatorio_agua_comunitaria",
    componentName: "PointIconDefault",
    description:
      "Local próprio para armazenar um volume de água que supra as demandas da comunidade. Os usos das águas de reservatório são destinados ao consumo humano, agricultura, comércio e outras atividades.",
    downloadLink: "",
    zIndex: 150,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_hidro_recursos_hidricos&PROPERTYNAME=classific,geom&CQL_FILTER=classific='reservatorio de agua'&maxFeatures=50&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "reservatorio_agua_comunitaria",
      "icon-size": [
        "interpolate",
        ["exponential", 2],
        ["zoom"],
        14,
        0.2,
        16,
        0.4,
        18,
        0.6,
        20,
        0.6,
      ],
      "icon-allow-overlap": true,
    },
  },
  {
    name: "Rio Gualaxo do Norte",
    categoria: "recurso_hidrico",
    visible: true,
    visibleUI: true,
    colorUI: "#8BE7FC",
    pattern: "",
    iconName: "",
    componentName: "LineDefault",
    description: "",
    downloadLink: "",
    zIndex: 100,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_hidro_rio_gualaxo&maxFeatures=50&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
    },
  },
  {
    name: "Subdistritos",
    categoria: "LayerComunidade",
    visible: true,
    visibleUI: false,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "circle",
    componentName: "PointComunidades",
    description: "",
    downloadLink: "",
    zIndex: 320,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_sub_distritos_ctb&CQL_FILTER=subdistrit NOT LIKE 'Monsenhor Horta' AND subdistrit NOT LIKE 'Paracatu de Cima'&maxFeatures=3000&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "circle",
      "icon-size": 0.4,
      "icon-allow-overlap": true,
      "icon-ignore-placement": true,
      "text-allow-overlap": true,
      "text-anchor": "top",
      "text-offset": [0, 0.6],
      "text-field": "{subdistrit}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 18,
    },
  },
  {
    name: "Monsenhor Horta",
    categoria: "LayerComunidade",
    visible: true,
    visibleUI: false,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "circle",
    componentName: "PointComunidades",
    description: "",
    downloadLink: "",
    zIndex: 320,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_sub_distritos_ctb&CQL_FILTER=subdistrit='Monsenhor Horta'&maxFeatures=3000&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "circle",
      "icon-size": 0.4,
      "icon-allow-overlap": true,
      "icon-ignore-placement": true,
      "text-allow-overlap": true,
      "text-anchor": "right",
      "text-offset": [0, 0.6],
      "text-field": "{subdistrit}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 18,
    },
  },
  {
    name: "Paracatu de Cima",
    categoria: "LayerComunidade",
    visible: true,
    visibleUI: false,
    colorUI: "#d4ecd5",
    pattern: "",
    iconName: "circle",
    componentName: "PointComunidades",
    description: "",
    downloadLink: "",
    zIndex: 320,
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_territorial_sub_distritos_ctb&CQL_FILTER=subdistrit='Paracatu de Cima'&maxFeatures=3000&outputFormat=application%2Fjson",
      },
    },
    layout: {
      visibility: "visible",
      "icon-image": "circle",
      "icon-size": 0.4,
      "icon-allow-overlap": true,
      "icon-ignore-placement": true,
      "text-allow-overlap": true,
      "text-anchor": "bottom",
      "text-offset": [0, -0.6],
      "text-field": "{subdistrit}",
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 18,
    },
  },
  {
    name: "Lama",
    categoria: "lama",
    visible: false,
    visibleUI: true,
    colorUI: "#d4ecd5",
    pattern: "lama4",
    iconName: "",
    zIndex: 300,
    componentName: "FillLama",
    description:
      "O rejeito de mineração é o que sobra quando se usa água para separar o minério de ferro do material cuja exploração econômica não é viável, até o momento atual. Em geral, esse rejeito é composto por minérios pobres (com baixa concentração de ferro) e areia, além da água, evidenciando o aspecto de lama. O “mar de lama” do rompimento da barragem de Fundão continha aproximadamente 60 milhões de metros cúbicos de rejeito de minério de ferro, que foram deslocados por aproximadamente 600km entre os rios Gualaxo do Norte, Rio do Carmo e a Bacia do Rio Doce até chegar à Foz do Rio Doce em Regência, no estado do Espírito Santo.",
    props: {
      source: {
        type: "geojson",
        generateId: true,
        data:
          "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_recursos_hidricos_lama_ctb&maxFeatures=3000&outputFormat=application%2Fjson",
        //"http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_recursos_hidricos_lama_ctb&maxFeatures=50&outputFormat=application%2Fjson"
        // "http://territorioatingido.com.br/geoserver/territorio-atingido/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=territorio-atingido%3Atab_geo_hidro_lama&maxFeatures=10&outputFormat=application%2Fjson",
      },
    },
  },
];
