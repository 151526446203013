export default [
  {
    path: "/territorio",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "mapa_territorios",
        path: "",
        component: () => import("@/views/Territorio"),
        meta: {
          title: "Território",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
        },
        children: [
          {
            name: "mapa_territorios",
            path: "",
            component: () => import("./pages/HomeTerritorio"),
            meta: {
              title: "Território",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
            },
          },
        ],
      },
      {
        name: "sobre_campinas",
        path: "campinas",
        component: () => import("./pages/Campinas/Campinas"),
        meta: {
          title: "Campinas",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
          showGoBack: false,
        },
        children: [
          {
            name: "sobre_campinas",
            path: "",
            component: () => import("./pages/Campinas/Sobre"),
            meta: {
              title: "O Rompimento",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_rompimento_campinas",
            path: "rompimento",
            component: () => import("./pages/Campinas/Rompimento"),
            meta: {
              title: "O Rompimento",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_cultura_campinas",
            path: "cultura",
            component: () => import("./pages/Campinas/Cultura"),
            meta: {
              title: "Cultura",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
        ],
      },

      {
        name: "sobre_mata_chaves",
        path: "matachaves",
        component: () => import("./pages/MataChaves/MataChaves"),
        meta: {
          title: "Mata do Chaves",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
          showGoBack: false,
        },
        children: [
          {
            name: "sobre_mata_chaves",
            path: "",
            component: () => import("./pages/MataChaves/Sobre"),
            meta: {
              title: "Mata do Chaves",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
        ],
      },

      {
        name: "sobre_monsenhor",
        path: "monsenhorhorta",
        component: () => import("./pages/MonsenhorHorta/MonsenhorHorta.vue"),
        meta: {
          title: "Monshor Horta",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
          showGoBack: false,
        },
        children: [
          {
            name: "sobre_monsenhor",
            path: "",
            component: () => import("./pages/MonsenhorHorta/Sobre"),
            meta: {
              title: "Sobre",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_ocupacao_territorio",
            path: "ocupacaoterritorio",
            component: () =>
              import("./pages/MonsenhorHorta/OcupacaoTerritorio"),
            meta: {
              title: "Ocupação e Território",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_cultura_monsenhor",
            path: "cultura",
            component: () => import("./pages/MonsenhorHorta/Cultura"),
            meta: {
              title: "Cultura",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
        ],
      },
      {
        name: "bicas",
        path: "bicas",
        component: () => import("./pages/Bicas/Bicas"),
        meta: {
          title: "Bicas",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
          showGoBack: false,
        },
        children: [
          {
            name: "sobre_bicas",
            path: "",
            component: () => import("./pages/Bicas/Sobre"),
            meta: {
              title: "Bicas",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
        ],
      },
      {
        name: "pedras",
        path: "pedras",
        component: () => import("./pages/Pedras/Pedras"),
        meta: {
          title: "Pedras",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
          showGoBack: false,
        },
        children: [
          {
            name: "sobre_pedras",
            path: "",
            component: () => import("./pages/Pedras/Sobre"),
            meta: {
              title: "Pedras",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_rompimento_pedras",
            path: "rompimento",
            component: () => import("./pages/Pedras/Rompimento"),
            meta: {
              title: "O Rompimento",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
        ],
      },

      {
        name: "sobre_aguas_claras",
        path: "aguasclaras",
        component: () => import("./pages/AguasClaras/AguasClaras"),
        meta: {
          title: "Águas Claras",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
          showGoBack: false,
        },
        children: [
          {
            name: "sobre_aguas_claras",
            path: "",
            component: () => import("./pages/AguasClaras/Sobre"),
            meta: {
              title: "Águas Claras",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_aguas_claras_cultura",
            path: "cultura",
            component: () => import("./pages/AguasClaras/Cultura"),
            meta: {
              title: "Cultura",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
        ],
      },

      {
        name: "sobre_paracatu",
        path: "paracatu",
        component: () => import("./pages/Paracatu/Paracatu"),
        meta: {
          title: "Paracatu",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
          showGoBack: false,
        },
        children: [
          {
            name: "sobre_paracatu",
            path: "",
            component: () => import("./pages/Paracatu/Sobre"),
            meta: {
              title: "Paracatu",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_paracatu_de_cima",
            path: "paracatudecima",
            component: () => import("./pages/Paracatu/ParacatuDeCima"),
            meta: {
              title: "Paracatu de Cima",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_paracatu_de_baixo",
            path: "paracatudebaixo",
            component: () => import("./pages/Paracatu/ParacatuDeBaixo"),
            meta: {
              title: "Paracatu de Baixo",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
        ],
      },

      {
        name: "sobre_santa_rita",
        path: "santarita",
        component: () => import("./pages/SantaRita/SantaRita"),
        meta: {
          title: "Santa Rita Durão",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
          showGoBack: false,
        },
        children: [
          {
            name: "sobre_santa_rita",
            path: "",
            component: () => import("./pages/SantaRita/Sobre"),
            meta: {
              title: "Sobre",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_nucleo_urbano_santa_rita",
            path: "nucleourbano",
            component: () => import("./pages/SantaRita/NucleoUrbano"),
            meta: {
              title: "Núcleo Urbano",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_cultura_santa_rita",
            path: "cultura",
            component: () => import("./pages/SantaRita/Cultura"),
            meta: {
              title: "Cultura",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_rompimento_santa_rita",
            path: "rompimento",
            component: () => import("./pages/SantaRita/Rompimento"),
            meta: {
              title: "O Rompimento",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
        ],
      },

      {
        name: "sobre_camargos",
        path: "camargos",
        component: () => import("./pages/Camargos/Camargos"),
        meta: {
          title: "Camargos",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
          showGoBack: false,
        },
        children: [
          {
            name: "sobre_camargos",
            path: "",
            component: () => import("./pages/Camargos/Sobre"),
            meta: {
              title: "Sobre",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_nucleo_urbano_camargos",
            path: "nucleourbano",
            component: () => import("./pages/Camargos/NucleoUrbano"),
            meta: {
              title: "Núcleo Urbano",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_cultura_camargos",
            path: "cultura",
            component: () => import("./pages/Camargos/Cultura"),
            meta: {
              title: "Cultura",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_rompimento_camargos",
            path: "rompimento",
            component: () => import("./pages/Camargos/Rompimento"),
            meta: {
              title: "O Rompimento",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
        ],
      },

      {
        name: "sobre_braunas",
        path: "braunas",
        component: () => import("./pages/Braunas/Braunas"),
        meta: {
          title: "Braúnas",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
          showGoBack: false,
        },
        children: [
          {
            name: "sobre_braunas",
            path: "",
            component: () => import("./pages/Braunas/Sobre"),
            meta: {
              title: "Sobre",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },

          {
            name: "sobre_rompimento_braunas",
            path: "rompimento",
            component: () => import("./pages/Braunas/Rompimento"),
            meta: {
              title: "O Rompimento",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
        ],
      },

      {
        name: "sobre_borba",
        path: "borba",
        component: () => import("./pages/Borba/Borba"),
        meta: {
          title: "Borba",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
          showGoBack: false,
        },
        children: [
          {
            name: "sobre_borba",
            path: "",
            component: () => import("./pages/Borba/Sobre"),
            meta: {
              title: "Borba",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_borba_rompimento",
            path: "rompimento",
            component: () => import("./pages/Borba/Rompimento"),
            meta: {
              title: "Rompimento",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
        ],
      },

      {
        name: "sobre_ponte_do_gama",
        path: "pontedogama",
        component: () => import("./pages/PonteDoGama/PonteDoGama"),
        meta: {
          title: "Ponte do Gama",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
          showGoBack: false,
        },
        children: [
          {
            name: "sobre_ponte_do_gama",
            path: "",
            component: () => import("./pages/PonteDoGama/Sobre"),
            meta: {
              title: "Ponte do Gama",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },

          {
            name: "sobre_ponte_do_gama_cultura",
            path: "cultura",
            component: () => import("./pages/PonteDoGama/Cultura"),
            meta: {
              title: "Cultura",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_ponte_do_gama_rompimento",
            path: "rompimento",
            component: () => import("./pages/PonteDoGama/Rompimento"),
            meta: {
              title: "Rompimento",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
        ],
      },

      {
        name: "bento_rodrigues",
        path: "bento",
        component: () => import("./pages/BentoRodrigues/BentoRodrigues"),
        meta: {
          title: "Bento Rodrigues",
          style: {
            backgroundColor: "#8BE7FC",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
        },
        children: [
          {
            name: "sobre_bento_rodrigues",
            path: "",
            component: () => import("./pages/BentoRodrigues/Sobre"),
            meta: {
              title: "Bento Rodrigues",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_rompimento_bento",
            path: "rompimento",
            component: () => import("./pages/BentoRodrigues/Rompimento"),
            meta: {
              title: "O Rompimento",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_caracteristica_nucleo_geral",
            path: "nucleogeral",
            component: () =>
              import("./pages/BentoRodrigues/CaracteristicaNucleoUrbano"),
            meta: {
              title: "Características do Núcleo Urbano",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_cultura_bento",
            path: "cultura",
            component: () => import("./pages/BentoRodrigues/Cultura"),
            meta: {
              title: "Cultura",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
            children: [
              {
                name: "sobre_cultura_bento",
                path: "",
                component: () => import("./pages/BentoRodrigues/CulturaInicio"),
                meta: {
                  title: "Cultura",
                  style: {
                    backgroundColor: "#8BE7FC",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "sobre_cultura_protecao_patrimonio",
                path: "patrimonio",
                component: () =>
                  import("./pages/BentoRodrigues/ProtecaoPatrimonio"),
                meta: {
                  title: "Proteção do Patrimônio construído",
                  style: {
                    backgroundColor: "#8BE7FC",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "sobre_cultura_manifestacoes_tradicionais",
                path: "manifestacoes",
                component: () =>
                  import("./pages/BentoRodrigues/ManifestacoesTradicionais"),
                meta: {
                  title: "Manifestações Tradicionais e Festividades Religiosas",
                  style: {
                    backgroundColor: "#8BE7FC",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "sobre_cultura_capela_sao_bento",
                path: "capelasaobento",
                component: () =>
                  import("./pages/BentoRodrigues/CapelaSaoBento"),
                meta: {
                  title: "Capela São Bento",
                  style: {
                    backgroundColor: "#8BE7FC",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "sobre_cultura_capela_nsra_merces",
                path: "capelanossasramerces",
                component: () =>
                  import("./pages/BentoRodrigues/NossaSenhoraMerces"),
                meta: {
                  title: "Capela de Nossa Senhora das Mercês",
                  style: {
                    backgroundColor: "#8BE7FC",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "sobre_cultura_capela_nsra_aparecida",
                path: "capela/nsraaparecida",
                component: () =>
                  import("./pages/BentoRodrigues/NossaSenhoraAparecida"),
                meta: {
                  title: "Capela de Nossa Senhora Aparecida",
                  style: {
                    backgroundColor: "#8BE7FC",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "sobre_cultura_lazer_convivio",
                path: "lazer",
                component: () => import("./pages/BentoRodrigues/LazerConvivio"),
                meta: {
                  title: "Lugares de lazer e convívio",
                  style: {
                    backgroundColor: "#8BE7FC",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
            ],
          },

          {
            name: "sobre_bento_atividade",
            path: "atividades",
            component: () => import("./pages/BentoRodrigues/Atividades"),
            meta: {
              title: "Atividades",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_bento_rompimento",
            path: "rompimento",
            component: () => import("./pages/BentoRodrigues/Rompimento"),
            meta: {
              title: "Rompimento",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
          },
          {
            name: "sobre_bento_tradicoes_resistencias",
            path: "tradicoes",
            component: () =>
              import(
                "./pages/BentoRodrigues/TradicoesResistencias/TradicoesResistenciasView"
              ),
            meta: {
              title: "Tradições e Resistências",
              style: {
                backgroundColor: "#8BE7FC",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
            children: [
              {
                name: "sobre_bento_tradicoes_resistencias",
                path: "",
                component: () =>
                  import(
                    "./pages/BentoRodrigues/TradicoesResistencias/TradicoesResistencias"
                  ),
                meta: {
                  title: "Tradições e Resistências",
                  style: {
                    backgroundColor: "#8BE7FC",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "sobre_bento_tradicoes_resistencias_resistencia",
                path: "resistencias",
                component: () =>
                  import(
                    "./pages/BentoRodrigues/TradicoesResistencias/Tradicoes"
                  ),
                meta: {
                  title: "Tradições e Resistências",
                  style: {
                    backgroundColor: "#8BE7FC",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "sobre_loucos_pelo_bento",
                path: "loucospelobento",
                component: () =>
                  import(
                    "./pages/BentoRodrigues/TradicoesResistencias/LoucosPeloBento"
                  ),
                meta: {
                  title: "Loucos pelo Bento",
                  style: {
                    backgroundColor: "#8BE7FC",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "sobre_bento_time_futebol",
                path: "timedefutebol",
                component: () =>
                  import("./pages/BentoRodrigues/TradicoesResistencias/OTime"),
                meta: {
                  title: "O time de futebol",
                  style: {
                    backgroundColor: "#8BE7FC",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "sobre_bento_refencias_simbolicas",
                path: "referenciassimbolicas",
                component: () =>
                  import(
                    "./pages/BentoRodrigues/TradicoesResistencias/ReferenciasSimbolicas"
                  ),
                meta: {
                  title: "Referências Simbólicas",
                  style: {
                    backgroundColor: "#8BE7FC",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
