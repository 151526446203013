export default [
  {
    path: "/",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "Home",
        path: "",
        component: () => import("@/views/Home"),
        meta: {
          style: {
            backgroundColor: "#04441F",
            logoSrc: "/logos/logo-selo-verde-branco.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
            footerLogo: "/logos/logo-caritas-main-branco.png",
            footerTextColor: "#fff",
          },
          breadcrumbs: [
            {
              label: "Home",
              to: "Home",
            },
          ],
        },
      },
    ],
  },
];
