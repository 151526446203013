<template>
  <transition>
    <router-view />
  </transition>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Mariana Território Atingido",
      meta: [
        {
          name: "description",
          content:
            "O Museu Virtual Mariana Território Atingido exibe mapas, fotos e histórias da população atingida de Mariana pelo rompimento da barragem de rejeitos de minérios de Fundão, em 2015, controlada pela Samarco Mineração S.A – um empreendimento conjunto das maiores empresas de mineração do mundo, a brasileira Vale S.A. e a anglo–australiana BHP Billiton. O site é um projeto de direito à memória e também uma ferramenta de apoio à luta das pessoas atingidas por reparação justa e integral.",
        },
        // O Museu Virtual Mariana Território Atingido exibe mapas, fotos e histórias da população atingida de Mariana pelo rompimento da barragem de rejeitos de minérios de Fundão.
      ],
    };
  },

  created() {},
};
</script>

<style></style>
