import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "./style/main.styl";

import store from "./store";
import "./plugins/axios";

// utils
import get from "lodash/get";
import has from "lodash/has";
import set from "lodash/set";

import VueMapbox from "@curupira/vue-mapbox";
import vuetify from "./plugins/vuetify";
Vue.config.MapBoxAccessToken =
  "pk.eyJ1IjoiZmVsaXh4eGd1aWxoZXJtZSIsImEiOiJjazh0ZWk3bTIwNXEzM2dsMnB1OTVnNWZiIn0.8L6VugkL_G8wVdRNzuEskg";
Vue.use(VueMapbox);

Vue.config.productionTip = false;

// SETUP COMPONENT INSTANCES GLOBALS
Vue.use(function(Vue) {
  Vue.prototype.$has = has;
  Vue.prototype.$get = get;
  Vue.prototype.$set = set;
});

import VueTour from "vue-tour";

require("vue-tour/dist/vue-tour.css");

Vue.component("ScrollTop", require("./components/ScrollTop.vue"));

Vue.use(VueTour);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
