import { routes as Sobre } from "@/modules/Sobre";
import { routes as Mapa } from "@/modules/Mapa";
import { routes as ConteudosTematicos } from "@/modules/ConteudosTematicos";
import { routes as Projeto } from "@/modules/Projeto";
import { routes as Territorio } from "@/modules/Territorio"
import { routes as Acervo } from "@/modules/AcervoPublicacoes"

export default [ 
  ...Sobre,
  ...Mapa,
  ...Projeto,
  ...ConteudosTematicos,
  ...Territorio,
  ...Acervo

];