export default [
  {
    path: "/projeto",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "projeto",
        path: "",
        component: () => import("@/views/Projeto"),
        meta: {
          title: "Sobre",
          style: {
            backgroundColor: "#E06919",
            logoSrc: "/logos/logo-selo-verde-branco.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
            footerLogo: "/logos/logo-caritas-branca.png",
            footerTextColor: "#fff",
          },
        },
        children: [
          {
            name: "projeto",
            path: "",
            component: () => import("../Projeto/pages/SobreProjeto"),
            meta: {
              title: "Sobre",
              style: {
                backgroundColor: "#E06919",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "mapa_comunitario",
            path: "mapacomunitario",
            component: () => import("../Projeto/pages/MapaComunitario"),
            meta: {
              title: "Mapa Comunitário",
              style: {
                backgroundColor: "#E06919",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "ficha_tecnica",
            path: "fichatecnica",
            component: () => import("../Projeto/pages/FichaTecnica"),
            meta: {
              title: "Ficha Técnica",
              style: {
                backgroundColor: "#E06919",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          
        ]
      },
      
      {
        name: "projeto_parceiros",
        path: "projeto/parceiros",
        component: () => import("../Projeto/pages/Parceiros"),
        meta: {
          title: "Parceiros",
          style: {
            backgroundColor: "#E06919",
            logoSrc: "/logos/logo-selo-verde-branco.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
            footerLogo: "/logos/logo-caritas-branca.png",
            footerTextColor: "#fff",
          },
        },
        children: [
          
        ]
      },
      {
        name: "projeto_cabf",
        path: "cabf",
        component: () => import("../Projeto/pages/Cabf"),
        meta: {
          title: "Comissão de Atingidos pela Barragem do Fundão",
          style: {
            backgroundColor: "#E06919",
            logoSrc: "/logos/logo-selo-verde-branco.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
            footerLogo: "/logos/logo-caritas-branca.png",
            footerTextColor: "#fff",
          },
        },
      },
      {
        name: "projeto_sirene",
        path: "sirene",
        component: () => import("../Projeto/pages/Sirene"),
        meta: {
          title: "Sirene",
          style: {
            backgroundColor: "#E06919",
            logoSrc: "/logos/logo-selo-verde-branco.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
            footerLogo: "/logos/logo-caritas-branca.png",
            footerTextColor: "#fff",
          },
        },
      }
      
    ],
  },
];
