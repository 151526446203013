export default [
  {
    path: "/sobre",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "about_page",
        path: "",
        component: () => import("@/views/About"),
        meta: {
          title: "Sobre",
          style: {
            backgroundColor: "#C92F05",
            logoSrc: "/logos/logo-selo-verde-branco.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
            footerLogo: "/logos/logo-caritas-branca.png",
            footerTextColor: "#fff",
          },
        },
        children: [
          {
            name: "cards",
            path: "",
            component: () => import("./pages/Caritas/Cards"),
            meta: {
              title: "Sobre",
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_caritas_brasileira",
            path: "brasileira",
            component: () => import("./pages/Caritas/Brasileira"),
            meta: {
              showGoBack: false,
              title: "Caritas Brasileira",
              subpage: true,

              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_caritas_internacional",
            path: "internacional",
            component: () => import("./pages/Caritas/Internacional"),
            meta: {
              showGoBack: false,
              subpage: true,
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_caritas_al",
            path: "latinoamerica",
            component: () => import("./pages/Caritas/AmericaLatinaCaribe"),
            meta: {
              showGoBack: false,
              subpage: true,
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_caritas_regional",
            path: "regionalmg",
            component: () => import("./pages/Caritas/RegionalMinasGerais"),
            meta: {
              showGoBack: false,
              subpage: true,
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },

          {
            name: "sobre_assessoria",
            path: "assessoria",
            component: () => import("./pages/Assessoria/Assessoria"),
            meta: {
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_assessoria_tecnica",
            path: "assessoria/tecnica",
            component: () => import("./pages/Assessoria/AssessoriaTecnica"),
            meta: {
              subpage: true,
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_assessoria_juridica",
            path: "assessoria/juridica",
            component: () => import("./pages/Assessoria/AssessoriaJuridica"),
            meta: {
              subpage: true,
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_cadastro",
            path: "cadastro",
            component: () => import("./pages/Cadastro/Cadastro"),
            meta: {
              subpage: true,
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_contexto_conquistas",
            path: "contextos/conquistas",
            component: () => import("./pages/Cadastro/ContextoConquista"),
            meta: {
              subpage: true,
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_reformulacao_cadastro",
            path: "cadastro/reformulacao",
            component: () => import("./pages/Cadastro/Formulario"),
            meta: {
              subpage: true,
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_cartografia_social_familiar",
            path: "cartografiasocial",
            component: () =>
              import("./pages/Cadastro/CartografiaSocialFamiliar"),
            meta: {
              subpage: true,
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_vistoria_advi",
            path: "vistoria",
            component: () => import("./pages/Cadastro/VistoriaAdvi"),
            meta: {
              subpage: true,
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_tomadas_de_termo",
            path: "tomadas",
            component: () => import("./pages/Cadastro/TomadasTermo"),
            meta: {
              subpage: true,
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_sistematizacao_dossie",
            path: "dossie",
            component: () => import("./pages/Cadastro/SistematizacaoDossie"),
            meta: {
              subpage: true,
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstfirstFooterLogo:
                  "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
          {
            name: "sobre_ati_continuada",
            path: "ati",
            component: () => import("./pages/Cadastro/AtiContinuada"),
            meta: {
              subpage: true,
              style: {
                backgroundColor: "#C92F05",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-branca.png",
                footerTextColor: "#fff",
              },
            },
          },
        ],
      },
      {
        name: "sobre_pipam",
        path: "/sobre/pipam",
        component: () => import("./pages/Pipam/Pipam"),
        meta: {
          showGoBack: false,
          subpage: false,
          style: {
            backgroundColor: "#C92F05",
            logoSrc: "/logos/logo-selo-verde-branco.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
            footerLogo: "/logos/logo-caritas-branca.png",
            footerTextColor: "#fff",
          },
        },
      },
    ],
  },
];
