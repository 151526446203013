export default [
  {
    path: "/conteudostematicos",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "conteudos_tematicos",
        path: "",
        component: () => import("@/views/ConteudosTematicos"), //@ = root do projeto / diretorio raiz do projeto
        meta: {
          title: "Sobre",
          style: {
            backgroundColor: "#EDBD43",
            logoSrc: "/logos/logo-selo-verde-preto.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome-preto.png",
            footerLogo: "/logos/logo-caritas-main-preto.png",
            footerTextColor: "#000",
          },
        },
        children: [
          {
            name: "conteudos_tematicos",
            path: "",
            component: () => import("./pages/temas.vue"),
            meta: {
              title: "Apresentação",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
            },
          },
          {
            name: "conteudos_tematicos_genero",
            path: "genero",
            component: () => import("./pages/Genero/GeneroApresentacao"),
            meta: {
              title: "Apresentação",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
            },
          },
          {
            name: "conteudos_tematicos_rel_comunitarias",
            path: "relacoescomunitarias",
            component: () => import("./pages/Genero/RelacoesComunitarias"),
            meta: {
              title: "Relações Comunitárias",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
            },
          },
          {
            name: "conteudos_tematicos_trabalho_renda",
            path: "trabalhoerenda",
            component: () => import("./pages/Genero/TrabalhoRenda"),
            meta: {
              title: "Trabalho e Renda",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
            },
          },
          {
            name: "conteudos_tematicos_auxilio_financeiro",
            path: "auxiliofinanceiro",
            component: () => import("./pages/Genero/AuxilioFinanceiro"),
            meta: {
              title: "Auxílio Financeiro Emergencial",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
            },
          },
          {
            name: "conteudos_tematicos_indenizacoes",
            path: "indenizacoes",
            component: () => import("./pages/Genero/Indenizacoes"),
            meta: {
              title: "Indenizações",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
            },
          },
          {
            name: "conteudos_tematicos_garimpeiras",
            path: "garimpeiras",
            component: () => import("./pages/Genero/Garimpeiras"),
            meta: {
              title: "Indenizações",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
            },
          },
          {
            name: "conteudos_tematicos_cultura_alimentar",
            path: "culturaalimentar",
            component: () => import("./pages/Alimentar/CulturaAlimentar"),
            meta: {
              title: "Cultura Alimentar",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
            children: [
              {
                name: "conteudos_tematicos_cultura_alimentar",
                path: "",
                component: () =>
                  import("./pages/Alimentar/SoberaniaSegurancaAlimentar"),
                meta: {
                  title: "Soberania e Segurança Alimentar",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },

              {
                name: "conteudo_soberania_beira_fogao",
                path: "beiradofogao",
                component: () => import("./pages/Alimentar/BeiradoFogao"),
                meta: {
                  title: "Beira do Fogão",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },

              {
                name: "conteudo_soberania_quintandas",
                path: "quintandas",
                component: () => import("./pages/Alimentar/Quitandas"),
                meta: {
                  title: "Quintandas",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
            ],
          },

          {
            name: "conteudos_tematicos_casas",
            path: "casas",
            component: () => import("./pages/Casa/Casa"),
            meta: {
              title: "Casa",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
            },
            children: [
              {
                name: "conteudos_tematicos_casas",
                path: "",
                component: () => import("./pages/Casa/Inicio"),
                meta: {
                  title: "Início",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },

              {
                name: "conteudos_tematicos_casas_deslocamento_forcado",
                path: "deslocamentoforcado",
                component: () => import("./pages/Casa/DeslocamentoForcado"),
                meta: {
                  title: "Deslocamento Forçado",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },

              {
                name: "conteudos_tematicos_casas_ocupacao_territorio",
                path: "ocupacaoterritorio",
                component: () => import("./pages/Casa/Ocupacao"),
                meta: {
                  title: "Ocupação do Território",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_casas_espaco_moradia",
                path: "espacomoradia",
                component: () => import("./pages/Casa/EspacoeMoradia"),
                meta: {
                  title: "Espaço e Moradia",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_casas_jardins_quintais",
                path: "jardinsequintais",
                component: () => import("./pages/Casa/JardinsQuintais"),
                meta: {
                  title: "Jardins e Quintais",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_casas_tecnicas_construtivas",
                path: "tecnicasconstrutivas",
                component: () => import("./pages/Casa/TecnicasTradicionais"),
                meta: {
                  title: "Técnicas Tradicionais Construtivas",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_casas_vias_acesso",
                path: "viasacesso",
                component: () => import("./pages/Casa/ViasAcesso"),
                meta: {
                  title: "Vias de Acesso",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_casas_desterritorializacao",
                path: "desterritorializacao",
                component: () => import("./pages/Casa/Desterritorializacao"),
                meta: {
                  title: "Desterritorialização",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },

              {
                name: "conteudos_tematicos_casas_vida_provisoria",
                path: "vidaprovisoria",
                component: () => import("./pages/Casa/VidaProvisoria"),
                meta: {
                  title: "Vida Provisória",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },

              {
                name: "conteudos_tematicos_casas_telhado",
                path: "telhado",
                component: () => import("./pages/Casa/Telhado"),
                meta: {
                  title: "Telhado",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },

              {
                name: "conteudos_tematicos_casas_pintura",
                path: "pintura",
                component: () => import("./pages/Casa/Pintura"),
                meta: {
                  title: "Pintura",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },

              {
                name: "conteudos_tematicos_casas_paredes",
                path: "paredes",
                component: () => import("./pages/Casa/Paredes"),
                meta: {
                  title: "Paredes",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },

              {
                name: "conteudos_tematicos_casas_portas_janelas",
                path: "portasejanelas",
                component: () => import("./pages/Casa/PortaseJanelas"),
                meta: {
                  title: "Portas e Janelas",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },

              {
                name: "conteudos_tematicos_casas_oficio_carpinteiro",
                path: "oficiocarpinteiro",
                component: () => import("./pages/Casa/OficioCarpinteiro"),
                meta: {
                  title: "O Ofício do Carpinteiro",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_casas_alicerce",
                path: "alicerce",
                component: () => import("./pages/Casa/Alicerce"),
                meta: {
                  title: "O Alicerce",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },

              {
                name: "conteudos_tematicos_casas_tecnica_preserva",
                path: "tecnicapreservacao",
                component: () => import("./pages/Casa/ConhecerTecnica"),
                meta: {
                  title: "Técnica de Preservação",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_casas_estrutura",
                path: "estrutura",
                component: () => import("./pages/Casa/Estrutura"),
                meta: {
                  title: "Estrutura",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
            ],
          },

          {
            name: "conteudos_tematicos_religiosidade",
            path: "religosidadepopular",
            component: () =>
              import("./pages/ReligiosidadePopular/ReligiosidadePopular.vue"),
            meta: {
              title: "Regiliosidade Popular",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
            },
            children: [
              {
                name: "conteudos_tematicos_religiosidade",
                path: "",
                component: () =>
                  import("./pages/ReligiosidadePopular/FoliaDeReis"),
                meta: {
                  title: "Folia de Reis",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_religiosidade_calendario",
                path: "calendarioliturgico",
                component: () =>
                  import("./pages/ReligiosidadePopular/CalendarioLiturgico"),
                meta: {
                  title: "Calendário Litúrgico",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
            ],
          },

          {
            name: "conteudos_tematicos_violacoes_direitos",
            path: "violacoesdireito",
            component: () =>
              import("./pages/ViolacoesDireitos/ViolacoesDireitos.vue"),
            meta: {
              title: "Violações de Direito",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
            },
            children: [
              {
                name: "conteudos_tematicos_violacoes_direitos",
                path: "",
                component: () => import("./pages/ViolacoesDireitos/Cover.vue"),
                meta: {
                  title: "Violações de Direitos",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_limitacao_cadastro",
                path: "limitescadastro",
                component: () =>
                  import("./pages/ViolacoesDireitos/LimitesCadastro.vue"),
                meta: {
                  title: "Limites do Cadastro",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_desconsideracao_cadastro",
                path: "desconsideracaocadastro",
                component: () =>
                  import("./pages/ViolacoesDireitos/DesconsideracaoCadastro"),
                meta: {
                  showGoBack: false,
                  title: "Desconsideração do Cadastro",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },

              {
                name: "conteudos_tematicos_descumprimento_prazos",
                path: "descumprimentoprazos",
                component: () =>
                  import("./pages/ViolacoesDireitos/DescumprimentoPrazos"),
                meta: {
                  showGoBack: false,
                  title: "Descumprimento de Prazos",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },

              {
                name: "conteudos_tematicos_violacoes_parcialidade_mediacao",
                path: "parcialidademediacoes",
                component: () =>
                  import("./pages/ViolacoesDireitos/ParcialidadeMediacoes"),
                meta: {
                  showGoBack: false,
                  title: "Parcialidade das Mediações",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },

              {
                name: "conteudos_tematicos_violacoes_indenizacao_terra",
                path: "indenizacao",
                component: () =>
                  import("./pages/ViolacoesDireitos/NaoIndenizacaoTerra"),
                meta: {
                  showGoBack: false,
                  title: "Não indenização da terra nua",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },

              {
                name: "conteudos_tematicos_violacoes_dano_moral",
                path: "danomoral",
                component: () => import("./pages/ViolacoesDireitos/DanoMoral"),
                meta: {
                  showGoBack: false,
                  title: "Dano moral atrelado à propriedade do terreno",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_violacoes_impossibilidade",
                path: "impossibilidadealteracao",
                component: () =>
                  import("./pages/ViolacoesDireitos/Impossibilidade"),
                meta: {
                  showGoBack: false,
                  title: "Impossibilidade de alteração dos termos",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
            ],
          },
          {
            name: "conteudos_tematicos_luta_continua",
            path: "lutacontinua",
            component: () => import("./pages/LutaContinua/LutaContinua"),
            meta: {
              title: "Luta pelo Reassentamento",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
            children: [
              {
                name: "conteudos_tematicos_luta_continua",
                path: "",
                component: () =>
                  import("./pages/LutaContinua/LutaReassentamento"),
                meta: {
                  title: "A Luta pelo Reassentamento",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "conteudos_tematicos_luta_musica",
                path: "musica",
                component: () => import("./pages/LutaContinua/Musica"),
                meta: {
                  title: "Música A LUTA",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "conteudos_tematicos_luta_crime",
                path: "prescricaocrime",
                component: () => import("./pages/LutaContinua/Crime"),
                meta: {
                  title: "Não prescrição do crime",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "conteudos_tematicos_luta_reparacao",
                path: "reparacaointegral",
                component: () =>
                  import("./pages/LutaContinua/ReparacaoIntegral"),
                meta: {
                  showGoBack: false,
                  title: "Reparação Integral",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
            ],
          },

          {
            name: "conteudos_tematicos_crianca_adolescente",
            path: "criancaadolescente",
            component: () =>
              import("./pages/CriancaAdolescente/CriancaAdolescente"),
            meta: {
              title: "Criança e Adolescente",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
            children: [
              {
                name: "conteudos_tematicos_crianca_adolescente",
                path: "",
                component: () =>
                  import("./pages/CriancaAdolescente/CriancaEAdolescente"),
                meta: {
                  title: "Criança e Adolescentes",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "conteudos_tematicos_crianca_adolescente_ser",
                path: "sercriancaadolescente",
                component: () =>
                  import("./pages/CriancaAdolescente/SerCriancaAdolescente"),
                meta: {
                  title: "Ser Criança e Adolescentes",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "conteudos_tematicos_crianca_adolescente_luto",
                path: "superacaoluto",
                component: () =>
                  import("./pages/CriancaAdolescente/SuperacaoDoLuto"),
                meta: {
                  title: "Ser Criança e Adolescentes",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name:
                  "conteudos_tematicos_crianca_adolescente_amadurecimento_precoce",
                path: "amadurecimentoprecoce",
                component: () =>
                  import("./pages/CriancaAdolescente/AmadurecimentoPrecoce"),
                meta: {
                  title: "Amadurecimento Precoce",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "conteudos_tematicos_crianca_adolescente_traumas",
                path: "traumas",
                component: () => import("./pages/CriancaAdolescente/Traumas"),
                meta: {
                  title: "Trauma",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "conteudos_tematicos_crianca_adolescente_educacao",
                path: "educacao",
                component: () => import("./pages/CriancaAdolescente/Educacao"),
                meta: {
                  title: "Educação",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
            ],
          },
          {
            name: "conteudos_tematicos_ser_atingido",
            path: "seratingido",
            component: () => import("./pages/SerAtingido/SerAtingido"),
            meta: {
              title: "Ser Atingido",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
              showGoBack: false,
            },
            children: [
              {
                name: "conteudos_tematicos_ser_atingido",
                path: "",
                component: () => import("./pages/SerAtingido/Cards"),
                meta: {
                  title: "Ser Atingido",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                  showGoBack: false,
                },
              },
              {
                name: "conteudos_tematicos_ser_atingido_oque_e",
                path: "queseratingido",
                component: () => import("./pages/SerAtingido/QueSerAtingido"),
                meta: {
                  showGoBack: false,
                  title: "Ser Atingido",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_ser_atingido_discriminacao",
                path: "discriminacao",
                component: () => import("./pages/SerAtingido/Discriminacao"),
                meta: {
                  title: "Discriminação",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_ser_atingido_estigma",

                path: "estigma",
                component: () => import("./pages/SerAtingido/Estigma"),
                meta: {
                  title: "Estigma",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_ser_atingido_trabalho_atingidos",
                path: "trabalhoatingidos",
                component: () =>
                  import("./pages/SerAtingido/TrabalhoAtingidos"),
                meta: {
                  title: "Trabalho dos Atingidos",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_ser_atingido_nao_reconhecidos",
                path: "naoreconhecidos",
                component: () => import("./pages/SerAtingido/NaoReconhecidos"),
                meta: {
                  title: "Não Reconhecidos",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_ser_atingido_tempos",
                path: "tempos",
                component: () => import("./pages/SerAtingido/Tempos"),
                meta: {
                  title: "Tempos",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_ser_atingido_idosos",
                path: "idosos",
                component: () => import("./pages/SerAtingido/Idosos"),
                meta: {
                  title: "Idosos",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
            ],
          },
          {
            name: "conteudos_tematicos_aguas",
            path: "aguas",
            component: () => import("./pages/Aguas/Aguas"),
            meta: {
              title: "Águas",
              style: {
                backgroundColor: "#EDBD43",
                logoSrc: "/logos/logo-selo-verde-preto.png",
                firstFooterLogo:
                  "/logos/territorio-atingido-logo-nome-preto.png",
                footerLogo: "/logos/logo-caritas-main-preto.png",
                footerTextColor: "#000",
              },
            },
            children: [
              {
                name: "conteudos_tematicos_aguas",
                path: "",
                component: () => import("./pages/Aguas/Inicio"),
                meta: {
                  title: "Início",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_aguas_rejeito",
                path: "rejeito",
                component: () => import("./pages/Aguas/RejeitoToxidade"),
                meta: {
                  title: "Rejeito e Toxidade",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
              {
                name: "conteudos_tematicos_aguas_lama",
                path: "lama",
                component: () => import("./pages/Aguas/Lama"),
                meta: {
                  title: "Lama",
                  style: {
                    backgroundColor: "#EDBD43",
                    logoSrc: "/logos/logo-selo-verde-preto.png",
                    firstFooterLogo:
                      "/logos/territorio-atingido-logo-nome-preto.png",
                    footerLogo: "/logos/logo-caritas-main-preto.png",
                    footerTextColor: "#000",
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
