export default [
  {
    path: "/acervopublicacoes",
    component: () => import("@/layout/Default"),
    children: [
      {
        name: "acervo_publicacoes",
        path: "",
        component: () => import("@/views/AcervoPublicacoes"),
        meta: {
          style: {
            backgroundColor: "#5D3F36",
            logoSrc: "/logos/logo-selo-verde-branco.png",
            firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
            footerLogo: "/logos/logo-caritas-main-branco.png",
            footerTextColor: "#fff",
          },
        },

        children: [
          {
            name: "acervo_publicacoes",
            path: "",
            component: () => import("./pages/AcervoPublicacoes"),
            meta: {
              style: {
                backgroundColor: "#5D3F36",
                logoSrc: "/logos/logo-selo-verde-branco.png",
                firstFooterLogo: "/logos/territorio-atingido-logo-nome.png",
                footerLogo: "/logos/logo-caritas-main-branco.png",
                footerTextColor: "#fff",
              },
            },
          }
        ]
      },
    ],
  },
];
